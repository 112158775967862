import React from "react"

import Seo from "../shared_components/seo"

import NetworkMonitor from "../components/network-monitor"

const IndexPage = () => {
  return (
    <div>
      <Seo title="eCash Network Monitor" />
      <div><img src="/img/ecash-logo.png" width={40} style={{margin: "4px"}} alt="eCash logo" /></div>
      <div style={{textAlign: "center", margin: "0 20px 20px 20px", fontSize: "1.4em"}}>
        eCash network status monitor <span style={{color: "red"}}>(beta)</span>
      </div>
      <ul>
        <li>Click on nodes to see current chaintip. Chaintips update in real-time.</li>
        <li>Click on seeders to see some random peers</li>
        <li>This is a beta site. If warnings appear, do not freak out. Some statuses take some time to sync correctly.</li>
      </ul>

      <NetworkMonitor />
    </div>
  )
}

export default IndexPage
