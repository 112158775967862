import React from "react"

import { StatusOk, StatusWarning, StatusBad, StatusProgress } from "./status-indicators"
import Folder from "./folding-container"

import "./seeders.scss"

const Seeders = ({ seeders }) => {
  var overallStatus = <StatusProgress>Loading...</StatusProgress>
  const numErrors = Object.keys(seeders).filter(key => seeders[key].error).length;
  const numSeeders = Object.keys(seeders).length
  if (numErrors === numSeeders) {
    overallStatus = <StatusBad>All seeders offline! Peering heavily disrupted!</StatusBad>
  } else if (numErrors >= numSeeders / 2) {
    overallStatus = <StatusBad>Majority of seeders offline! Peering likely disrupted.</StatusBad>
  } else if (numErrors > 0) {
    overallStatus = <StatusWarning>Some seeders offline</StatusWarning>
  } else if (numSeeders > 0) {
    overallStatus = <StatusOk>Green</StatusOk>
  }

  var seederElements = [];
  for (const seederAddress in seeders) {
    const seeder = seeders[seederAddress];
    var seederStatus = null
    var folderContent = null;
    if (seeder.error) {
      seederStatus = <StatusBad>{seederAddress}</StatusBad>
      folderContent = <div style={{color: "red"}}>{seeder.error}</div>
    } else {
      seederStatus = <StatusOk>{seederAddress}</StatusOk>
      folderContent = [
        <div key={seederAddress + "-peers"}>Random subset of seed peers:</div>
      ].concat(seeder.seeds.map(seed => (
        <div key={seed}>{seed}</div>
      )))
    }

    seederElements.push(
      <Folder header={seederStatus} key={seederAddress}>
        <div className="folder-seeder-content">
          {folderContent}
        </div>
      </Folder>
    )
  }

  return (
    <div>
      <div>Peering from Seeders: {overallStatus}</div>
      {seederElements}
    </div>
  )
}

export default Seeders
