import React, { Component } from "react"

import "./folding-container.scss"

export default class Folder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.initOpen,
    }
  }

  toggleOpen = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const showChildren = this.state.isOpen ? "inherit" : "none"
    return (
      <div className="folding-container">
        <button className="folding-container-header" onClick={this.toggleOpen} onKeyDown={this.toggleOpen}>
          {this.props.header}
        </button>
        <span style={{display: showChildren}}>
          {this.props.children}
        </span>
      </div>
    )
  }
}

Folder.defaultProps = {
  initOpen: false,
}
