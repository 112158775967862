import React from "react"

import { StatusOk, StatusWarning, StatusProgress } from "./status-indicators"
import Folder from "./folding-container"
import { ForkStatus } from "./network-status"

function CompareVersions(a, b) {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }

  const regex = new RegExp(/[a-z-]*([0-9]+).([0-9]+).([0-9]+)/)
  const matchA = a.match(regex)
  const matchB = b.match(regex)

  const majorA = Number(matchA[1])
  const minorA = Number(matchA[2])
  const revA = Number(matchA[3])
  const majorB = Number(matchB[1])
  const minorB = Number(matchB[2])
  const revB = Number(matchB[3])

  if (majorA !== majorB) {
    return majorA - majorB;
  }
  if (minorA !== minorB) {
    return minorA - minorB;
  }
  if (revA !== revB) {
    return revA - revB;
  }
  return a - b;
}

const Chaintips = ({ nodes }) => {
  var nodeStates = [];

  var latestHash = null;
  // Get latest node version and use that as the source of truth to compare other nodes against
  var latestVersion = null;
  for (const key in nodes) {
    if (CompareVersions(key, latestVersion) > 0) {
      latestVersion = key;
      latestHash = nodes[key].blockhash;
    }
  }

  Object.keys(nodes).sort(CompareVersions).forEach(key => {
    const node = nodes[key];
    var nodeStatus = null;
    var decoratedBlockHash = null;

    var nodeName = node.name;
    const nameMatch = nodeName.match(/[a-z-]*([0-9.]+)/)
    if (nameMatch) {
      var comment = "";
      if (node.chain === "testnet") {
        comment = " (" + node.chain + ")"
      }
      nodeName = "Bitcoin ABC " + nameMatch[1] + comment;
    }

    if (node.blockhash) {
      if (latestHash && node.blockhash !== latestHash) {
          nodeStatus = <StatusWarning>{nodeName}</StatusWarning>;
          decoratedBlockHash = <span>{node.blockhash}</span>
      } else {
          nodeStatus = <StatusOk>{nodeName}</StatusOk>;
          decoratedBlockHash = <a href={"https://blockchair.com/ecash/block/" + node.blockhash}>{node.blockhash}</a>
      }
    } else {
        nodeStatus = <StatusProgress>{nodeName}</StatusProgress>;
        decoratedBlockHash = <span>(Syncing)</span>;
    }

    var decoratedMedianTime = null;
    if (node.mediantime) {
      decoratedMedianTime = new Date(node.mediantime * 1000).toUTCString();
    }

    var mempool = null;
    if ("mempoolmemoryusage" in node) {
      var decoratedMempoolMemoryUsage = node.mempoolmemoryusage;
      var usageUnit = "bytes";
      if (decoratedMempoolMemoryUsage > 1024) {
        decoratedMempoolMemoryUsage /= 1024;
        usageUnit = "KB"
      }
      if (decoratedMempoolMemoryUsage > 1024) {
        decoratedMempoolMemoryUsage /= 1024;
        usageUnit = "MB"
      }
      mempool = <span>{node.mempooltxcount} txs, {decoratedMempoolMemoryUsage.toFixed(2)} {usageUnit}</span>
    }

    nodeStates.push(
      <Folder header={nodeStatus} key={String(nodeStates.length)}>
        <div style={{fontSize: "0.8em"}}>
          <div>Current chaintip: {decoratedBlockHash}</div>
          <div>Median Time: {decoratedMedianTime}</div>
          <div>Chainwork: {node.chainwork}</div>
          <div>Mempool: {mempool}</div>
        </div>
      </Folder>
    );
  })

  return (
    <div>
      <div>Chain Split Detection: <ForkStatus nodes={nodes} /></div>
      {nodeStates}
    </div>
  )
}

export default Chaintips
