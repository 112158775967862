
const CurrentVersionColor = "#00008B"

const UpgradeColor = "#DAA520"
const UpgradeLinkColor = "#B8860B"

const MapVersionGradient = [
  "#7070B0",
  "#966CA0",
  "#B4816E",
  "#BF9744",
  UpgradeColor,
]

const MapTypeColor = "#CD0BC3"
const AvalancheColor = "#FF21D0"

export {
  CurrentVersionColor,

  UpgradeColor,
  UpgradeLinkColor,

  MapVersionGradient,

  MapTypeColor,
  AvalancheColor
}
