import React from "react"

import { StatusOk, StatusWarning, StatusBad, StatusProgress } from "./status-indicators"

function ChainsDetected(nodes) {
  const chains = new Set();
  for (const key in nodes) {
    const node = nodes[key];
    if (node.blockhash) {
      chains.add(node.blockhash);
    }
  }

  return chains.size;
}

const NetworkStatus = ({ nodes }) => {
  // TODO: Incorporate seeder statuses
  const chains = ChainsDetected(nodes);
  switch (chains) {
    case 0:
      return <StatusProgress>Loading...</StatusProgress>
    case 1:
      return <StatusOk>Green</StatusOk>
    case 2:
      return <StatusWarning>Possible network disruption!</StatusWarning>
    default:
      return <StatusBad>Network unstable!</StatusBad>
  }
}

const ForkStatus = ({ nodes }) => {
  const chains = ChainsDetected(nodes);
  switch (chains) {
    case 0:
      return <StatusProgress>Loading...</StatusProgress>
    case 1:
      return <StatusOk>None</StatusOk>
    case 2:
      return <StatusWarning>Possible fork detected!</StatusWarning>
    default:
      return <StatusBad>Multiple forks detected!</StatusBad>
  }
}

export { NetworkStatus, ForkStatus }
