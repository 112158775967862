import React, { Component } from "react"

import Chaintips from "./chaintips"
import Nodes from "./nodes"
import Seeders from "./seeders"
import { NetworkStatus } from "./network-status"

const ChainButton = {
  margin: "6px",
  padding: "3px 8px",
  fontSize: "2em",
  border: "0",
  borderRadius: "6px",
  boxShadow: "1px 1px 2px #333",
}

const UnselectedChainButton = Object.assign({
  background: "#ddd",
  color: "#231F20",
}, ChainButton)

const SelectedChainButton = Object.assign({
  background: "#00ABE7",
  color: "white",
}, ChainButton)

function isLocalhost() {
  return "localhost" === window.location.hostname || "127.0.0.1" === window.location.hostname
}

export default class NetworkMonitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChain: "mainnet",
      network: {},
      statusnodes: {},
      statusnodesbychain: {
        mainnet: {},
        testnet: {},
      },
      seeders: {},
      testnetseeders: {},
    };
  }

  componentDidMount() {
    var ws;
    if (isLocalhost()) {
      ws = new WebSocket("ws://localhost:8081");
    } else {
      ws = new WebSocket("wss://status.cash/ws/");
    }
    ws.onmessage = (e) => {
      var newState = JSON.parse(e.data);
      if (isLocalhost()) {
        console.log(newState);
      }

      newState.statusnodesbychain = {
        mainnet: {},
        testnet: {},
      }
      for (const k in newState.statusnodes) {
        newState.statusnodes[k].name = k;
        newState.statusnodesbychain[newState.statusnodes[k].chain][k] = newState.statusnodes[k];
      }
      this.setState(prevState => newState);
    }
  }

  selectMainnet = () => {
    this.setState({
      selectedChain: "mainnet"
    });
  }

  selectTestnet = () => {
    this.setState({
      selectedChain: "testnet"
    });
  }

  render() {
    var mainnetButtonStyle = SelectedChainButton;
    var testnetButtonStyle = UnselectedChainButton;
    var nodes = this.state.network.nodes;
    var statusNodes = this.state.statusnodesbychain.mainnet;
    var seeders = this.state.seeders;

    if (this.state.selectedChain === "testnet") {
      mainnetButtonStyle = UnselectedChainButton;
      testnetButtonStyle = SelectedChainButton;
      nodes = this.state.network.testnetnodes;
      statusNodes = this.state.statusnodesbychain.testnet;
      seeders = this.state.testnetseeders;
    }

    return (
      <div style={{margin: "10px"}}>
        <button style={mainnetButtonStyle} onClick={this.selectMainnet}>Mainnet</button>
        <button style={testnetButtonStyle} onClick={this.selectTestnet}>Testnet</button>

        <Nodes chain={this.state.selectedChain} nodes={nodes} statusNodes={statusNodes} />
        <div style={{margin: "30px 0 10px 0"}}>
          Overall Network Health: <NetworkStatus nodes={statusNodes} />
        </div>
        <div>
          Network status breakdown:
        </div>
        <div style={{display: "flex", flexFlow: "row wrap"}}>
          <div style={{flex: "1 60%", minWidth: "650px"}}>
            <Chaintips nodes={statusNodes} />
          </div>
          <div style={{flex: "1 40%", minWidth: "300px"}}>
            <Seeders seeders={seeders} />
          </div>
        </div>
      </div>
    )
  }
}

NetworkMonitor.defaultProps = {
}
