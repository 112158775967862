import React, { Component } from "react"

import { create as CreateChart, color as ChartColor, Circle, DropShadowFilter } from "@amcharts/amcharts4/core"
import { MapChart, MapPolygonSeries, MapImageSeries, projections as MapProjections } from "@amcharts/amcharts4/maps"
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow"

import * as colors from "./colors"
import { UserAgentIsUpgraded } from "./useragents"

const MapTypeButton = {
  margin: "4px",
  fontSize: "1.2em",
  border: "0",
  borderRadius: "6px",
  boxShadow: "1px 1px 2px #333",
}

const UnselectedMapTypeButton = Object.assign({
  background: "#ddd",
  color: "#231F20",
}, MapTypeButton)

const SelectedMapTypeButton = Object.assign({
  background: colors.MapTypeColor,
  color: "white",
}, MapTypeButton)

export default class NodeMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapType: "nodecount",
    }
    this.state.mapId = "nodemap" + this.props.chain + this.state.mapType;
  }

  getMapScore(node) {
    if (this.state.mapType == "nodecount") {
      return 1;
    }

    var score = 0;
    for (const k in this.props.statusNodes) {
      var statusNode = this.props.statusNodes[k]
      for (const peerAddr in statusNode.peerdetails) {
        if (peerAddr == node.address) {
          var peerDetails = statusNode.peerdetails[peerAddr];
          score += peerDetails.availability_score;
        }
      }
    }
    return score;
  }

  setSeriesData() {
    if (this.props.nodes) {
      var data = {};
      var atLeastOneScore = false;

      this.props.nodes.forEach(node => {
        const key = node.location.latitude.toString() + "&" + node.location.longitude.toString();

        const mapScore = this.getMapScore(node)
        if (mapScore > 0) {
          atLeastOneScore = true;
        }

        if (key in data) {
          data[key].count += mapScore;
          data[key].upgraded += UserAgentIsUpgraded(node.useragent) ? 1 : 0;
        } else {
          data[key] = {
            "latitude": node.location.latitude,
            "longitude": node.location.longitude,
            "count": mapScore,
            "upgraded": UserAgentIsUpgraded(node.useragent) ? 1 : 0,
            "color": ChartColor(colors.AvalancheColor),
          }
        }
      });

      // Evaluate colors
      if (this.state.mapType === "nodecount") {
        for (var key in data) {
          var gradientIndex = Math.ceil(data[key].upgraded * (colors.MapVersionGradient.length - 1) / data[key].count);
          data[key].color = ChartColor(colors.MapVersionGradient[gradientIndex]);
        }
      }

      this.imageSeries.data = Object.values(data);

      this.imageSeries.heatRules.getIndex(0).min = this.state.mapType === "nodecount" ? 5 : 3;
      if (atLeastOneScore) {
        this.imageSeries.heatRules.getIndex(0).max = this.state.mapType === "nodecount" && this.props.nodes.length < 40 ? 10 : 20;
      } else {
        this.imageSeries.heatRules.getIndex(0).max = this.imageSeries.heatRules.getIndex(0).min;
      }
    }
  }

  componentDidMount() {
    let map = CreateChart(this.state.mapId, MapChart);
    map.geodata = am4geodata_worldLow;
    map.projection = new MapProjections.NaturalEarth1();

    let polygonSeries = new MapPolygonSeries();
    polygonSeries.useGeodata = true;
    polygonSeries.exclude = ["AQ"];
    map.series.push(polygonSeries);

    let imageSeries = map.series.push(new MapImageSeries());

    let shadow = imageSeries.filters.push(new DropShadowFilter());
    shadow.dx = 1;
    shadow.dy = 2;
    shadow.blur = 0;
    shadow.color = ChartColor("#444444");
    shadow.opacity = 0.7;

    let imageSeriesTemplate = imageSeries.mapImages.template;
    let circle = imageSeriesTemplate.createChild(Circle);
    circle.stroke = ChartColor("#000000");
    circle.strokeWidth = 1.2;
    circle.nonScaling = true;
    circle.tooltipText = "{count}";
    imageSeriesTemplate.propertyFields.latitude = "latitude";
    imageSeriesTemplate.propertyFields.longitude = "longitude";
    imageSeriesTemplate.propertyFields.fill = "color";
    imageSeries.dataFields.value = "count";
    imageSeries.heatRules.push({
      "target": circle,
      "property": "radius",
      "min": 5,
      "max": 20,
      "dataField": "value"
    })

    this.imageSeries = imageSeries;
    this.setSeriesData();
  }

  componentDidUpdate(nextProps) {
    this.setSeriesData();
  }

  selectNodeCountMap = () => {
    this.setState({
      mapType: "nodecount",
      mapId: "nodemap" + this.props.chain + "nodecount",
    });
  }

  selectScoreMap = () => {
    this.setState({
      mapType: "score",
      mapId: "nodemap" + this.props.chain + "score",
    });
  }

  render() {
    var nodeCountsMapButtonStyle = SelectedMapTypeButton;
    var scoreMapButtonStyle = UnselectedMapTypeButton;
    var description = "Larger circles correspond to more nodes in the same area.";

    if (this.state.mapType === "score") {
      nodeCountsMapButtonStyle = UnselectedMapTypeButton;
      scoreMapButtonStyle = SelectedMapTypeButton;
      description = "Larger circles correspond to higher cumulative availability score of all nodes in the same area. Scores of 0 indicate nodes not serving avalanche traffic.";
    }

    return (
      <div style={{margin: "10px"}}>
        <button style={nodeCountsMapButtonStyle} onClick={this.selectNodeCountMap}>Node Counts</button>
        <button style={scoreMapButtonStyle} onClick={this.selectScoreMap}>Availability Scores</button>
        <div>{description}</div>

        <div id={this.state.mapId} style={{height: "40vmin"}} />
        <span style={{fontSize: "10px"}}>
          This site or product includes IP2Location LITE data available from http://www.ip2location.com.
        </span>
      </div>
    )
  }
}

NodeMap.defaultProps = {
}
