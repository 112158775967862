import React from "react"

import { StatusProgress } from "./status-indicators"
import NodeMap from "./nodes-map"
import * as colors from "./colors"
import { UserAgentIsUpgraded } from "./useragents"

const Nodes = ({ chain, nodes, statusNodes }) => {
  var nodeCountFormatted = <StatusProgress>Loading...</StatusProgress>
  var nodeVersions = <StatusProgress>Loading...</StatusProgress>
  var upgradeReadyCount = 0
  var upgradeReadyFormatted = null;
  if (nodes) {
    const nodeCount = nodes.length
    nodeCountFormatted = <span style={{fontSize: "1.3em", fontWeight: "bold", color: colors.CurrentVersionColor}}>{nodeCount}</span>

    // Count unique versions
    var versionSet = {};
    for (const i in nodes) {
      const node = nodes[i];
      if (!(node.useragent in versionSet)) {
        versionSet[node.useragent] = 1;
      } else {
        versionSet[node.useragent] += 1;
      }
    }

    // Sort versions by count
    var versions = [];
    for (const useragent in versionSet) {
      versions.push({
        "useragent": useragent,
        "count": versionSet[useragent],
      });
    }
    versions.sort(function(a, b) { return b.count - a.count });

    nodeVersions = [
      <div key="node-versions-header" style={{textDecoration: "underline"}}># &nbsp;&nbsp;&nbsp; User Agent</div>
    ]
    for (const v in versions) {
      const version = versions[v];

      var nodeStyle = {color: colors.CurrentVersionColor}
      if (UserAgentIsUpgraded(version.useragent)) {
        upgradeReadyCount += version.count;
        nodeStyle.color = colors.UpgradeColor;
        nodeStyle.fontWeight = "bold";
      }

      nodeVersions.push(<div key={version.useragent} style={nodeStyle}>{version.count} &nbsp;&nbsp;&nbsp; {version.useragent}</div>);
    }

    var upgradeReadyPercent = nodeCount ? (upgradeReadyCount / nodeCount * 100.0).toFixed(0) : 0;
    if (upgradeReadyPercent >= 10) {
      upgradeReadyFormatted = (<span> - <span style={{color: colors.UpgradeColor, fontWeight: "bold"}}>
        {upgradeReadyPercent}% ready for the <a style={{color: colors.UpgradeLinkColor}} href="https://www.bitcoinabc.org/upgrade/" target="_blank" rel="noreferrer">next upgrade</a></span>
      </span>)
    }
  }

  return (
    <div>
      <div>
        Reachable eCash Nodes: {nodeCountFormatted}{upgradeReadyFormatted}
      </div>
      <div style={{display: "flex", flexFlow: "row wrap"}}>
        <div style={{flex: "1 1 30%", minWidth: "300px"}}>
          Reachable node versions:
          <div style={{marginLeft: "20px"}}>
            {nodeVersions}
          </div>
        </div>
        <div style={{flex: "1 1 70%", minHeight: "300px"}}>
          <NodeMap chain={chain} nodes={nodes} statusNodes={statusNodes} />
        </div>
      </div>
    </div>
  )
}

export default Nodes
