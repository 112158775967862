import React from "react"

import "@fortawesome/fontawesome-free/css/all.css"

const iconStyle = {
  margin: "4px",
}

const StatusOk = ({ children }) => {
  return (
    <span style={{color: "darkgreen"}}>
      <i className="fas fa-check-circle" style={iconStyle} />
      <span>{children}</span>
    </span>
  )
}

const StatusWarning = ({ children }) => {
  return (
    <span style={{color: "darkorange"}}>
      <i className="fas fa-exclamation-triangle" style={iconStyle} />
      <span>{children}</span>
    </span>
  )
}

const StatusBad = ({ children }) => {
  return (
    <span style={{color: "darkred"}}>
      <i className="fas fa-skull-crossbones" style={iconStyle} />
      <span>{children}</span>
    </span>
  )
}

const StatusProgress = ({ children }) => {
  return (
    <span style={{color: "blue"}}>
      <i className="fas fa-sync-alt" style={iconStyle} />
      <span>{children}</span>
    </span>
  )
}

const StatusNull = ({ children }) => {
  return (
    <span style={{color: "gray"}}>
      <i className="fas fa-minus-circle" style={iconStyle} />
      <span>{children}</span>
    </span>
  )
}

export { StatusOk, StatusWarning, StatusBad, StatusProgress, StatusNull }
